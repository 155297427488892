.slick-list,
.slick-slider,
.slick-track {
  position: relative;
  display: block;
}

.slick-loading .slick-slide,
.slick-loading .slick-track {
  visibility: hidden;
}

.slick-slider {
  box-sizing: border-box;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
  outline: none !important;
  max-width: 770px;
  margin: 0 auto;
  position: relative;
}

/* background: linear-gradient(260.02deg,
  #ffffff 8.36%,
  rgba(255, 255, 255, 0) 93.45%); */

.slick-slider * {
  outline: none !important;
}

.slick-list {
  overflow: hidden;
  margin: 0;
  padding: 0;
}

.slick-list:focus {
  outline: 0;
}

.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}

.slick-slider .slick-list,
.slick-slider .slick-track {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.slick-track {
  top: 0;
  left: 0;
}

.slick-track:after,
.slick-track:before {
  display: table;
  content: "";
}

.slick-track:after {
  clear: both;
}

.slick-slide {
  display: none;
  float: left;
  height: 100%;
  min-height: 1px;
  width: 85vw;
  max-width: 330px;
  position: relative;
  text-align: center;
  opacity: 0.5;
  transition: 0.2s;
  transition-delay: 0.4s;
  overflow: hidden;
  padding: 3px;
}

@media screen and (min-width: 350px) {
  .slick-slide {
    filter: blur(3px);
    max-width: 300px;
    padding: 5px;
  }
}

@media screen and (min-width: 700px) {
  .slick-slide {
    transform: scale(0.95);
    max-width: 350px;
  }
}

.slick-slide *[class*="StatusContainer"] {
  margin-right: 0;
  margin-left: 0;
  display: inline-block;
}

.slick-slide.slick-center,
.slick-slide.slick-active {
  opacity: 0.999;
  filter: blur(0.000001px);
  transform: scale(1);
  transition-delay: 0s;
}

.slick-slide:not(.slick-center) *[class*="StatusButton"] {
  opacity: 0.01;
  z-index: -1;
  top: -100px;
  transform: scale(0.1);
}

[dir="rtl"] .slick-slide {
  float: right;
}

.slick-slide img {
  display: block;
}

.slick-slide.slick-loading img {
  display: none;
}

.slick-slide.dragging img {
  pointer-events: none;
}

.slick-initialized .slick-slide {
  display: block;
}

.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}

.slick-arrow.slick-hidden {
  display: none;
}

.slick-dots,
.slick-next,
.slick-prev {
  position: absolute;
  display: block;
  padding: 0;
}

.slick-dots li button:before,
.slick-next:before,
.slick-prev:before {
  font-family: slick;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.slick-next,
.slick-prev {
  font-size: 15px;
  line-height: 20px;
  top: 50%;
  width: 20px;
  height: 20px;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  cursor: pointer;
  color: transparent;
  border: none;
  outline: 0;
  border-radius: 50%;
}

.slick-next:focus,
.slick-next:hover,
.slick-prev:focus,
.slick-prev:hover {
  color: transparent;
  outline: 0;
  background: 0 0;
}

.slick-next:focus:before,
.slick-next:hover:before,
.slick-prev:focus:before,
.slick-prev:hover:before {
  opacity: 1;
}

.slick-next.slick-disabled:before,
.slick-prev.slick-disabled:before {
  opacity: 0.25;
}

.slick-next:before,
.slick-prev:before {
  display: block;
  position: relative;
  width: 100%;
  height: 100%;
  background-color: #004b93;
  background-size: auto 40%;
  background-repeat: no-repeat;
  border-radius: 50%;
}

.slick-prev {
  left: -25px;
}

[dir="rtl"] .slick-prev {
  right: -30px;
  left: auto;
}

.slick-prev:before {
  content: "";
  background-position: 45% 50%;
  background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg width='10px' height='18px' viewBox='0 0 10 18' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Ctitle%3EPath%3C/title%3E%3Cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg id='iconfinder_Chevron_Right_4781843' transform='translate(-11.000000, -7.000000)' fill='%23FFFFFF' fill-rule='nonzero'%3E%3Cg id='Layer_2' transform='translate(10.000000, 7.000000)'%3E%3Cpath d='M2,18.0000167 C1.73419702,18.0015368 1.47871883,17.8971865 1.29,17.71 C1.10068735,17.5222334 0.994201676,17.2666375 0.994201676,17 C0.994201676,16.7333625 1.10068735,16.4777666 1.29,16.29 L8.59,9 L1.29,1.71 C1.03634143,1.45634143 0.937276483,1.08662601 1.03012196,0.740121963 C1.12296744,0.393617914 1.39361791,0.122967443 1.74012196,0.0301219633 C2.08662601,-0.0627235168 2.45634143,0.0363414312 2.71,0.29 L10.71,8.29 C10.8993127,8.4777666 11.0057983,8.73336246 11.0057983,9 C11.0057983,9.26663754 10.8993127,9.5222334 10.71,9.71 L2.71,17.71 C2.52128117,17.8971865 2.26580298,18.0015368 2,18.0000167 Z' id='Path' transform='translate(6.000000, 8.997951) scale(-1, 1) translate(-6.000000, -8.997951) '%3E%3C/path%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
}

.slick-next:before,
[dir="rtl"] .slick-prev:before {
  content: "";
  background-position: 55% 50%;
  background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg width='10px' height='18px' viewBox='0 0 10 18' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Ctitle%3EPath%3C/title%3E%3Cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg id='iconfinder_Chevron_Right_4781843' transform='translate(-11.000000, -7.000000)' fill='%23FFFFFF' fill-rule='nonzero'%3E%3Cg id='Layer_2' transform='translate(10.000000, 7.000000)'%3E%3Cpath d='M2,18.0000167 C1.73419702,18.0015368 1.47871883,17.8971865 1.29,17.71 C1.10068735,17.5222334 0.994201676,17.2666375 0.994201676,17 C0.994201676,16.7333625 1.10068735,16.4777666 1.29,16.29 L8.59,9 L1.29,1.71 C1.03634143,1.45634143 0.937276483,1.08662601 1.03012196,0.740121963 C1.12296744,0.393617914 1.39361791,0.122967443 1.74012196,0.0301219633 C2.08662601,-0.0627235168 2.45634143,0.0363414312 2.71,0.29 L10.71,8.29 C10.8993127,8.4777666 11.0057983,8.73336246 11.0057983,9 C11.0057983,9.26663754 10.8993127,9.5222334 10.71,9.71 L2.71,17.71 C2.52128117,17.8971865 2.26580298,18.0015368 2,18.0000167 Z' id='Path'%3E%3C/path%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
}

.slick-next {
  right: -30px;
}

[dir="rtl"] .slick-next {
  right: auto;
  left: -25px;
}

[dir="rtl"] .slick-next:before {
  content: "←";
}

@media (max-width: 800px) {

  .slick-prev,
  .slick-next {
    z-index: 5;
  }

  .slick-prev {
    left: -7px;
  }

  .slick-next {
    right: -7px;
  }
}

.slick-dotted.slick-slider {
  margin-bottom: 30px;
}

.slick-dots {
  bottom: 0px;
  width: 100%;
  margin: 0;
  list-style: none;
  text-align: center;
}

.slick-dots li {
  position: relative;
  display: inline-block;
  width: 10px;
  height: 10px;
  margin: 0 5px;
  padding: 0;
  cursor: pointer;
}

.slick-dots li button {
  font-size: 0;
  line-height: 0;
  display: block;
  width: 100%;
  height: 100%;
  padding: 5px;
  cursor: pointer;
  color: transparent;
  border: 0;
  outline: 0;
  background: 0 0;
}

.slick-dots li button:focus,
.slick-dots li button:hover {
  outline: 0;
}

.slick-dots li button:focus:before,
.slick-dots li button:hover:before {
  opacity: 1;
}

.slick-dots li button:before {
  font-size: 6px;
  line-height: 20px;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  content: "";
  text-align: center;
  opacity: 0.25;
  color: #000;
  background: #044e91;
  border-radius: 50%;
}

.slick-dots li.slick-active button:before {
  opacity: 0.999;
  color: #000;
}

/*# sourceMappingURL=slick-theme.min.css.map */
/* the slides */

.slick-list {
  margin: 0 -5px;
}